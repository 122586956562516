import './NoticeLabel.css';

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';

type NoticeLabelElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveNoticeLabelButtonProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface NoticeLabelProps extends PrimitiveNoticeLabelButtonProps {
  variant?: 'normal' | 'danger' | 'required' | 'primary' | 'fill' | 'overlay' | 'outline' | 'ghost';
  size?: 'small' | 'medium' | 'large';
}

export const NoticeLabel = forwardRef<NoticeLabelElement, NoticeLabelProps>(
  ({ variant = 'normal', size = 'medium', ...originalProps }, forwardRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <div
        className={`notice-label notice-label-${variant} notice-label-${size} ${className}`}
        {...props}
        ref={forwardRef}
      />
    );
  },
);
