import './FileSelectorLabel.css';
import {
  ChangeEventHandler,
  ComponentProps,
  DragEventHandler,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { Text } from 'src/components/primitives/Text';
import { blobFromFile, getMimeTypeAlertMessage, isValidFileType } from 'src/lib/uploadFile';
import { Field } from 'src/components/primitives/Field';
import { FormOptionsContext } from 'src/components/control/Form';
import { useToast } from 'src/lib/toast';
import { useI18n } from 'src/lib/i18n';

export const FileSelectorLabel: FC<
  PropsWithChildren<{
    id: string;
    accept: string;
    multiple?: boolean;
    active?: boolean;
    icon?: ComponentProps<typeof Icon>['name'];
    onUpload: (image: Blob) => void | Promise<void>;
  }>
> = ({ id, accept, multiple = false, active = true, icon = 'add_photo_alternate', onUpload, children }) => {
  const uploaderId = id + '_uploader';
  const { readOnly } = useContext(FormOptionsContext);
  const [isDragOver, setDragOver] = useState(false);
  const toast = useToast();
  const { i18n } = useI18n();

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      if (!event.target.files) return;
      for (const file of event.target.files) {
        if (isValidFileType(file, accept)) {
          blobFromFile(file).then(onUpload);
        }
      }
      event.target.files = null;
    },
    [onUpload],
  );

  const onDragOver: DragEventHandler<HTMLLabelElement> = useCallback(
    (event) => {
      if (readOnly) return;
      event.preventDefault();
      setDragOver(true);
    },
    [setDragOver, readOnly],
  );

  const onDragLeave: DragEventHandler<HTMLLabelElement> = useCallback(() => {
    setDragOver(false);
  }, [setDragOver]);

  const onDrop: DragEventHandler<HTMLLabelElement> = useCallback(
    async (event) => {
      if (readOnly) return;
      event.preventDefault();
      setDragOver(false);
      if (!event.dataTransfer.files) return;
      for (const file of event.dataTransfer.files) {
        if (isValidFileType(file, accept)) {
          blobFromFile(file).then(onUpload);
        } else {
          toast({ message: getMimeTypeAlertMessage(accept, i18n), variant: 'error' });
        }
      }
    },
    [onUpload, setDragOver, readOnly],
  );

  return (
    <Field asChild className={`file-selector-field ${isDragOver && 'drag-over'}`} draggableHeight>
      <label htmlFor={uploaderId} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
        {active ? (
          <>
            <Icon name={icon} className="icon" aria-label="" fontSize="large" />
            <Text color="hint" variant="body">
              {children}
            </Text>
          </>
        ) : (
          children
        )}
        <input
          type="file"
          id={uploaderId}
          multiple={multiple}
          accept={accept}
          onChange={onChange}
          disabled={readOnly}
          aria-hidden="false"
        />
      </label>
    </Field>
  );
};
