import { factoryAddress } from 'src/catalog/factories/address';
import { Meta, Story } from 'src/catalog/Story';
import { AddressCard } from 'src/components/features/address/AddressCard';
import { Button } from 'src/components/primitives/Button';

const meta: Meta<typeof AddressCard> = {
  title: 'Address Card',
  args: {
    address: factoryAddress(),
  },
  template: (args) => <AddressCard {...args} />,
};

export default meta;

export const primary: Story<typeof AddressCard> = {
  name: 'Primary Address',
  args: {
    address: factoryAddress('primary'),
  },
};

export const selectable: Story<typeof AddressCard> = {
  name: 'Selectable Address(selected:false)',
  args: {
    address: factoryAddress(),
    selected: false,
    onSelect: () => {},
  },
};

export const selected: Story<typeof AddressCard> = {
  name: 'Selectable Address(selected:true)',
  args: {
    address: factoryAddress(),
    selected: true,
    onSelect: () => {},
  },
};

export const withActions: Story<typeof AddressCard> = {
  name: 'With Actions',
  args: {
    address: factoryAddress(),
    children: (
      <>
        <Button>Edit</Button>
        <Button variant="danger">Delete</Button>
      </>
    ),
  },
};
