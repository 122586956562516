import './MultipleMediaFilesField.css';

import { ChangeEventHandler, DragEvent, FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormControl } from 'src/components/control/FormControl';
import { Icon } from 'src/components/primitives/Icon';
import { MediaImage, MediaImageSource } from 'src/components/primitives/MediaImage';
import { Spinner } from 'src/components/primitives/Spinner';
import { HStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { blobFromFile, uploadMediaFile, getMimeTypeAlertMessage, isValidFileType } from 'src/lib/uploadFile';
import { MediaFile } from 'src/models/v1/media_file';
import { useToast } from 'src/lib/toast';

export const MultipleMediaFilesField: FC<{
  name: string;
  id?: string;
  mediaFiles: Array<MediaFile>;
  max?: number;
  label?: ReactNode;
  hint?: ReactNode;
  accept?: string | undefined;
  options?: RegisterOptions<FieldValues, string>;
}> = ({ name, id = name, mediaFiles, max, label, hint, accept, options }) => {
  const { i18n } = useI18n();
  const toast = useToast();
  const [isDragOver, setDragOver] = useState(false);
  const [currentStates, setCurrentStates] = useState<MediaImageSource[]>(mediaFiles);
  const { register, setValue } = useFormContext();

  const onUpload: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      const files = event.target.files!;
      const promises = [];
      for (const file of files) {
        const promise = blobFromFile(file).then((blob) => {
          const mediaImageId = Math.random().toString(36).substring(2, 11);
          setCurrentStates((e) => ({
            ...e,
            [mediaImageId]: 'file:uploading',
          }));
          uploadMediaFile(blob).then((result) => {
            setCurrentStates((e) => {
              const mediaFiles: MediaImageSource[] = { ...e, [mediaImageId]: { ...result } };
              setValue(
                name,
                Object.values(mediaFiles).map((item) => typeof item !== 'string' && item.gid),
                { shouldValidate: true, shouldDirty: true },
              );
              return mediaFiles;
            });
          });
        });
        promises.push(promise);
      }
      await Promise.all(promises);
    },
    [setValue, setCurrentStates],
  );

  const onDelete = useCallback(
    (deletion: MediaFile) => {
      setCurrentStates((prevMediaFiles) => {
        const updatedMediaFiles = Object.values(prevMediaFiles).filter(
          (item) => typeof item !== 'string' && item.gid !== deletion.gid,
        );
        setValue(
          name,
          updatedMediaFiles.map((file) => typeof file !== 'string' && file.gid),
          { shouldValidate: true, shouldDirty: true },
        );
        return updatedMediaFiles;
      });
    },
    [setValue, setCurrentStates],
  );

  const onDragOver = useCallback(
    (event: DragEvent<HTMLElement>) => {
      event.preventDefault();
      setDragOver(true);
    },
    [setDragOver],
  );

  const onDragLeave = useCallback(() => setDragOver(false), [setDragOver]);

  const onDrop = useCallback(
    async (event: React.DragEvent<HTMLElement>) => {
      event.preventDefault();
      setDragOver(false);
      const files = event.dataTransfer.files!;
      const promises = [];
      let hasInvalidFile = false;

      for (const file of files) {
        if (!accept || isValidFileType(file, accept)) {
          const promise = blobFromFile(file).then((blob) => {
            const mediaImageId = Math.random().toString(36).substring(2, 11);
            setCurrentStates((e) => ({
              ...e,
              [mediaImageId]: 'file:uploading',
            }));
            uploadMediaFile(blob).then((result) => {
              setCurrentStates((e) => {
                const mediaFiles: MediaImageSource[] = { ...e, [mediaImageId]: { ...result } };
                setValue(
                  name,
                  Object.values(mediaFiles).map((item) => typeof item !== 'string' && item.gid),
                  { shouldValidate: true, shouldDirty: true },
                );
                return mediaFiles;
              });
            });
          });
          promises.push(promise);
        } else {
          hasInvalidFile = true;
        }
      }
      await Promise.all(promises);
      if (hasInvalidFile && accept) {
        toast({ message: getMimeTypeAlertMessage(accept, i18n), variant: 'error' });
      }
    },
    [setValue, setDragOver, setCurrentStates, accept, i18n, toast],
  );

  const actualOptions: RegisterOptions<FieldValues, string> | undefined = useMemo(() => {
    const actualOptions = options ? { ...options } : undefined;
    if (actualOptions?.required) {
      actualOptions.validate = (value: Array<string> | undefined | null) => (value?.length || 0) > 0;
    }
    delete actualOptions?.required;
    return actualOptions;
  }, [options]);

  return (
    <FormControl
      id={id}
      name={name}
      label={label || i18n.t(`attributes.${name}`)}
      required={options?.required ? true : false}
    >
      {typeof hint == 'string' ? <Text>{hint}</Text> : hint}
      <HStack className="multiple-media-files-preview" justify="start" m="none">
        {Object.values(currentStates).map((status, index) =>
          status !== 'file:uploading' ? (
            <div className="container" key={status.gid}>
              <MediaImage className="image" src={status.url} size="1024x1024" />
              <Icon
                name="close"
                role="button"
                onClick={() => onDelete(status)}
                className="delete"
                aria-label={i18n.t('label.remove')}
              />
            </div>
          ) : (
            <Spinner key={index} />
          ),
        )}
      </HStack>
      {(typeof max == 'undefined' || Object.keys(currentStates).length < max) && (
        <label
          className={`multiple-media-files-field ${isDragOver && 'drag-over'}`}
          htmlFor={name}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <Icon name="add_photo_alternate" className="icon" aria-label="" color="disabled" fontSize="large" />
          <Text color="hint" variant="body">
            {i18n.t('label.upload_image')}
          </Text>
          <input
            id={id}
            {...register(name, actualOptions)}
            type="file"
            multiple
            accept={accept}
            value={[]}
            onChange={onUpload}
            onBlur={() => {}}
            aria-hidden="false"
          />
        </label>
      )}
    </FormControl>
  );
};
