import { CurrentStatusContext } from 'src/lib/currentStatus';
import { AccountProfile } from './AccountProfile';
import { Meta, Story } from 'src/catalog/Story';
import { factoryAccount } from 'src/catalog/factories/account';

const meta: Meta<typeof AccountProfile> = {
  title: 'Account Profile',
  args: {
    account: factoryAccount('iconic'),
  },
  template: (args) => {
    return (
      <CurrentStatusContext value={{ me: args.account, permissions: [] }}>
        <AccountProfile {...args} />
      </CurrentStatusContext>
    );
  },
};

export default meta;

export const proAccountProfile: Story<typeof AccountProfile> = {
  name: 'Pro Account Profile',
  args: {
    account: factoryAccount('pro'),
  },
};
