import { Menu } from 'src/components/singleton/Menu';
import { Meta, Story } from 'src/catalog/Story';
import { CurrentStatus, CurrentStatusContext } from 'src/lib/currentStatus';
import { Account } from 'src/models/v1/account';
import { factoryAccount } from 'src/catalog/factories/account';

const me: Account = {
  ...factoryAccount(),
};

const meta: Meta<typeof Menu, CurrentStatus> = {
  title: 'Menu',
  args: {
    me,
    permissions: [],
  },
  template: (args) => (
    <CurrentStatusContext value={args}>
      <Menu isVisible onClose={() => {}} />
    </CurrentStatusContext>
  ),
};

export default meta;

export const unauthorized: Story<typeof Menu, CurrentStatus> = {
  name: 'Unauthorized Menu',
  args: {
    me: null,
    permissions: [],
  },
};

export const ticket: Story<typeof Menu, CurrentStatus> = {
  name: 'Has Ticket Permission',
  args: {
    me,
    permissions: [{ name: 'ticket' }],
  },
};
