import { CurrentStatusContext } from 'src/lib/currentStatus';
import { AccountCard } from './AccountCard';
import { Meta, Story } from 'src/catalog/Story';
import { factoryAccount } from 'src/catalog/factories/account';

const meta: Meta<typeof AccountCard> = {
  title: 'Account Card',
  args: {
    account: factoryAccount(),
  },
  template: (args) => {
    return (
      <CurrentStatusContext value={{ me: null, permissions: [] }}>
        <AccountCard {...args} />
      </CurrentStatusContext>
    );
  },
};

export default meta;

export const basicAccountCard: Story<typeof AccountCard> = {
  name: 'Basic Account Card',
  args: {
    account: factoryAccount('iconic'),
  },
};

export const proAccountCard: Story<typeof AccountCard> = {
  name: 'Pro Account Card',
  args: {
    account: factoryAccount('pro'),
  },
};
