import { FC, TransitionStartFunction, useTransition } from 'react';
import { useRevalidator } from 'react-router';
import { onBecomePrimaryAddress, onDeleteAddress } from 'src/actions/address';
import { AddressCard } from 'src/components/features/address/AddressCard';
import { RegisterAddressButton } from 'src/components/features/address/RegisterAddressButton';
import { Button } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { useI18n } from 'src/lib/i18n';
import { Address } from 'src/models/v1/address';

export const AddressManagementView: FC<{ addresses: Array<Address> }> = ({ addresses }) => {
  const [isPending, startTransition] = useTransition();
  return (
    <>
      {addresses.map((address) => (
        <AddressManagementViewItem
          key={address.gid}
          address={address}
          isPending={isPending}
          startTransition={startTransition}
        />
      ))}
      <RegisterAddressButton />
    </>
  );
};

export const AddressManagementViewItem: FC<{
  address: Address;
  isPending: boolean;
  startTransition: TransitionStartFunction;
}> = ({ address, isPending, startTransition }) => {
  const { i18n } = useI18n();
  const { revalidate: onSuccess } = useRevalidator();
  return (
    <AddressCard address={address}>
      <Button
        variant="danger"
        size="small"
        disabled={isPending}
        onClick={onDeleteAddress({ address, startTransition, onSuccess })}
      >
        {i18n.t('action.delete')}
      </Button>
      <Button
        variant="outline"
        size="small"
        disabled={isPending || address.primary}
        onClick={onBecomePrimaryAddress({ address, startTransition, onSuccess })}
      >
        {i18n.t('action.make_primary_address')}
      </Button>
    </AddressCard>
  );
};
