import './SingleMediaFileField.css';

import { ChangeEventHandler, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { FormControl } from 'src/components/control/FormControl';
import { Icon } from 'src/components/primitives/Icon';
import { MediaImage } from 'src/components/primitives/MediaImage';
import { Spinner } from 'src/components/primitives/Spinner';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { blobFromFile, uploadMediaFile, getMimeTypeAlertMessage, isValidFileType } from 'src/lib/uploadFile';
import { useToast } from 'src/lib/toast';

type MediaImageSource = 'file:uploading' | `http://${string}` | `https://${string}`;

export const SingleMediaFileField: FC<{
  name: string;
  id?: string;
  url: string | undefined;
  label?: ReactNode;
  accept?: string | undefined;
  options?: RegisterOptions<FieldValues, string>;
}> = ({ name, id = name, url, label, accept, options }) => {
  const { i18n } = useI18n();
  const toast = useToast();
  const uploader_id = name + '_uploader';
  const [isDragOver, setDragOver] = useState(false);
  const [currentStates, setCurrentStates] = useState<MediaImageSource>();
  const { register, setValue } = useFormContext();
  const onUpload: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (e) => {
      const file = e.target.files![0];
      const blob = await blobFromFile(file);
      setCurrentStates('file:uploading');
      const result = await uploadMediaFile(blob);
      setCurrentStates(result.url as MediaImageSource);
      setValue(name, result.gid, { shouldValidate: true, shouldDirty: true });
    },
    [setValue, setCurrentStates],
  );
  const onDelete = useCallback(() => {
    setCurrentStates(undefined);
    setValue(name, undefined, { shouldValidate: true, shouldDirty: true });
  }, [setValue]);

  const onDragOver = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setDragOver(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setDragOver(false);
  }, []);

  const onDrop = useCallback(
    async (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      setDragOver(false);
      const file = e.dataTransfer.files[0];
      if (!accept || isValidFileType(file, accept)) {
        const blob = await blobFromFile(file);
        setCurrentStates('file:uploading');
        const result = await uploadMediaFile(blob);
        setCurrentStates(result.url as MediaImageSource);
        setValue(name, result.gid, { shouldValidate: true, shouldDirty: true });
      } else {
        toast({ message: getMimeTypeAlertMessage(accept, i18n), variant: 'error' });
      }
    },
    [setCurrentStates, setDragOver, accept, i18n, toast],
  );

  useEffect(() => {
    setCurrentStates(url as MediaImageSource);
  }, [url, setCurrentStates]);

  return (
    <FormControl
      id={uploader_id}
      name={name}
      required={options?.required ? true : false}
      label={label || i18n.t(`attributes.${name}`)}
    >
      {currentStates ? (
        <div className="single-media-file-preview">
          {currentStates === 'file:uploading' ? (
            <Spinner />
          ) : (
            <>
              <MediaImage className="image" src={currentStates} size="1024x1024" />
              <Icon
                name="close"
                role="button"
                onClick={onDelete}
                className="delete"
                aria-label={i18n.t('label.remove')}
              />
            </>
          )}
        </div>
      ) : (
        <label
          className={`single-media-file-field ${isDragOver && 'drag-over'}`}
          htmlFor={uploader_id}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <Icon name="add_photo_alternate" className="icon" aria-label="" fontSize="large" />
          <Text color="hint" variant="body">
            {i18n.t('label.upload_image')}
          </Text>
          <input type="file" id={uploader_id} accept={accept} onChange={onUpload} aria-hidden="false" />
        </label>
      )}
      <input type="hidden" id={id} {...register(name, options)} onBlur={() => {}} />
      <ErrorMessage name={name} label={label} />
    </FormControl>
  );
};
